const getResults = (total, _page, perPage) => {
    let $itemsTotalWrapper = $(".items-total-wrapper");
    let from = 0;
    let to = 0;

    if(total != 0) {
        from = _page > 1 ? (_page - 1) * perPage :  _page;

        to = _page * perPage;
        to = to < total ? to : total;
    }

    $itemsTotalWrapper.find(".showingFrom").text(+from);
    $itemsTotalWrapper.find(".showingTo").text(+to);
    $itemsTotalWrapper.find(".showingTotal").text(+total);
}

module.exports = {
    getResults: getResults
};