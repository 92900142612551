import './blocks/cards/card-category';
import './blocks/cards/card-video';

import './blocks/containers/container';
import './blocks/containers/row';
import './blocks/containers/column';

import './blocks/content/hero-slider';
import './blocks/content/hero-slide';
import './blocks/content/partner-link';

//import './blocks/content/pages-slider';
//import './blocks/content/pages-slide';

//import './blocks/content/button-outline';

import './blocks/tabs/nav-tabs';
import './blocks/tabs/tab-button';
import './blocks/tabs/tab-content';
import './blocks/tabs/tab-pane';
