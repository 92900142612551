import functions from '../../functions';

if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        InnerBlocks,
    } = window.wp.editor;

    const ALLOWED_BLOCKS = ['custom-gutenberg/column'];

    registerBlockType('custom-gutenberg/row', {
        // built-in attributes
        title: 'Row',
        description: 'Custom row',
        icon: '',
        category: 'custom-containers',
        example: {},

        // built-in functions
        edit({ attributes, setAttributes, className }) {

            const {
                classes
            } = attributes;

            className = functions.getClassNames(className);
            if(classes != className) setAttributes({classes: className});

            const MY_TEMPLATE = [
                [ 'custom-gutenberg/column', {} ],
            ];

            return (
                <div class={ "row " + classes }>
                    <InnerBlocks allowedBlocks={ ALLOWED_BLOCKS } template={ MY_TEMPLATE } />
                </div>
            );
        },

        save({ attributes }) {

            const {
                classes
            } = attributes;

            return (
                <div class={ "row " + (classes ? ' ' + classes : '') }>
                    <InnerBlocks.Content />
                </div>
            );
        },
    });
}