import functions from '../functions.js';
import Slider from "../components/Slider";
import { details } from './details';
import { DetailsCart } from './details-cart.js';
import { InputGuests } from "../components/input-guests";
import { inquiry } from '../inquiry.js';

$(() => {
    detailsTravel.init();
})

const detailsTravel = {
    $details: null,
    $form: null,
    form: null,
    params: {
        objectId: null, 
        unitId: null, 
        guests: null, 
        dates: null,
        dateFrom: null, 
        dateTo: null, 
        objectGroupId: null,
        calcErrorMessages: null,
    },
    numberOfBeds: null,
    rangeOfPersons: null,
    loadUnits() {
        let params = {};
        params.publicId = this.params.objectId;
        params.template = 'units';
        params.objectGroupId = this.params.objectGroupId;
        params.partnerId = this.params.partnerId;

        return $.get('/services/details/', params);
    },
    init() {
        this.$details = $('.page-type-details');
        this.params.objectGroupId = this.$details.attr('data-objectgroupid');

        if(! this.$details[0] || this.params.objectGroupId != 6) {
            return;
        }

        this.getParams();

        this.params.objectGroupId = this.$details.attr('data-objectgroupid');
        this.params.objectId = this.$details.attr('data-objectid');
        this.params.partnerId = this.$details.attr('data-partnerid');
        //this.params.calcErrorMessages = $('[data-calcErrorMessages]')[0] ? JSON.parse($('[data-calcErrorMessages]').attr('data-calcErrorMessages')) : null;

        this.params.currencyIdFrom = $('[data-currencyIdFrom]').attr('data-currencyIdFrom');
        this.params.currencyIdTo = $('[data-currencyIdTo]').attr('data-currencyIdTo');
        this.params.hide = $('[data-hide]').attr('data-hide');
        this.params.calcPP = $('[data-calcPP]').attr('data-calcPP');

        /*if(this.params.guests) {
            $('[name=guests]').val(this.params.guests);
            $('.input-guests')[0]._inputGuests.elemChange($('.input-guests')[0]._inputGuests);
        }*/

        this.initDepartureCalendar();
        this.setDefaultDeparture();

        this.$units = $('.units');
        this.$unitsSpinner = $('.units-spinner');

        //this.setUnitsQuantity();
        //this.setUnitQuantityForDate();

        //(! this.params.guests || ! this.params.dateFrom) && this.setUnitRates();

        //this.getCalculation();

        $('.departure').on('click', e => {
            $('.departure').removeClass('active');

            const $target = $(e.currentTarget);
            $target.addClass('active');
            this.params.dateFrom = $target.attr('data-datefrom');
            $('[data-datefrom=' + this.params.dateFrom + ']').addClass('active');
            this.params.dateTo = $target.attr('data-dateto');

            //this.printDates();

            //functions.setUrlData({'dateFrom': this.params.dateFrom, 'guests': this.params.guests }, true, true);
            functions.setUrlData({'dateFrom': this.params.dateFrom }, true, true);

        });

        $('.departure-sm').on('click', e => {
            const $target = $(e.currentTarget);
            this.params.dateFrom = $target.attr('data-datefrom');

            //functions.setUrlData({'dateFrom': this.params.dateFrom, 'guests': this.params.guests }, true, true);
            functions.setUrlData({'dateFrom': this.params.dateFrom }, true, true);

            functions.scrollToElement('#booking', 0);
        });

        /*$('[name=guests]').on('change', e => {
            e.preventDefault();
            const $target = $(e.currentTarget);

            if(! $target.parents('#inquiry').length) {
                this.params.unitId = $target.attr('data-unitid');
                this.params.guests = $target.val();
                if(this.params.guests) {
                    const $unit = $('.unit[data-unitid="' + this.params.unitId + '"]');
                    $unit.find('.add-to-cart').attr('data-guests', this.params.guests);
                }
    
                //functions.setUrlData({'dateFrom': this.params.dateFrom, 'guests': this.params.guests }, true, true);
                this.getCalculation();
            }
        });*/

        //this.cart = $('.details-cart').length ? new DetailsCart(this.params) : [];

        /*if(this.params.dateFrom && this.params.dateTo) {
            let dateFrom = functions.formatDate(this.params.dateFrom);
            let dateTo = functions.formatDate(this.params.dateTo);
            $('.unit').each((index, unit) => {
                const $unit = $(unit);
                $unit.find('.dates').text(dateFrom + ' - ' + dateTo);
            });
        }*/

        $('[name="rangeOfPersons"]').on('change', e => {
            const $target = $(e.currentTarget);
            const value = this.rangeOfPersons = $target.val();

            this.fadeUnits();

            $('.unit').each((index, item) => {
                const $unit = $(item);
                const unitId = $unit.attr('data-unitid');

                let hasUnitRate = this.hasUnitRate(unitId, this.params.dateFrom);

                if(hasUnitRate) {
                    let numberOfBeds = $unit.attr('data-numberOfBeds');
                    let minPersons = $unit.attr('data-minPersons');

                    if(! value || (value >= minPersons && value <= numberOfBeds)) {
                        $unit.removeClass('d-none');
                    } else {
                        $unit.addClass('d-none');
                    }
                }

            });

            if(! $('.unit').not('.d-none').length) {
                $('.msg-no-units').removeClass('d-none');
            } else {
                $('.msg-no-units').addClass('d-none');
            }
        });

        /*$('[name="numberOfBeds"]').on('change', e => {
            const $target = $(e.currentTarget);
            const value = this.numberOfBeds = $target.val();

            $('.unit').each((index, item) => {
                const $unit = $(item);
                const unitId = $unit.attr('data-unitid');

                let hasUnitRate = this.hasUnitRate(unitId, this.params.dateFrom);

                if(hasUnitRate) {
                    let numberOfBeds = $unit.attr('data-numberOfBeds');
                    if(numberOfBeds == value || ! value) {
                        $unit.removeClass('d-none');
                    } else {
                        $unit.addClass('d-none');
                    }
                }
            });

            if(! $('.unit').not('.d-none').length) {
                $('.msg-no-units').removeClass('d-none');
            } else {
                $('.msg-no-units').addClass('d-none');
            }
        });*/

        $(window).on('hashchange', e => {
            this.getParams();

            this.setDeparture();
            //this.setGuestsInstances();
            //(! this.params.guests || ! this.params.dateFrom) && this.setUnitRates();
            (! this.params.dateFrom) && this.setUnitRates();
            this.params.dateFrom && this.setUnitQuantityForDate();
            //this.getCalculation();

            $('.unit').each((index, unit) => {
                const $unit = $(unit);
                const unitId = this.params.unitId = $unit.attr('data-unitid');
                this.params.guests = $unit.find('[name=guests]').val();

                if(this.params.guests) {
                    $unit.find('.add-to-cart').attr('data-guests', this.params.guests);
                }
                if(this.params.dateFrom && this.params.dateTo) {
                    $unit.find('.dates').text(functions.formatDate(this.params.dateFrom) + ' - ' + functions.formatDate(this.params.dateTo));
                }

                this.getCalculation();
            });
        });

        $('.btn-print').on('click', e => {
            const params = $('.btn-print').attr('data-params');

            if(params) {
                const url = location.protocol + '//' + location.hostname + '/services/pdf/' + params;
                window.open(url, '_blank').focus();
            }
        });

        /*$('.btn-booking').on('click', e => {
            e.preventDefault();

            if(! this.cart.cartItems.length) {
                $('#detailsCartModal').modal('show');
                setTimeout(() => {
                    $('#detailsCartModal').modal('hide');
                }, 2500);
            }
        });*/

        this.$unitsSpinner.addClass('active');
        this.loadUnits().then(response => {
            if(response.status) {
                this.$units.html(response.view);

                this.params.calcErrorMessages = $('[data-calcErrorMessages]')[0] ? JSON.parse($('[data-calcErrorMessages]').attr('data-calcErrorMessages')) : null;

                this.setUnitsQuantity();
                this.setUnitQuantityForDate();

                (! this.params.guests || ! this.params.dateFrom) && this.setUnitRates();

                this.$units.find('.input-guests').each((i, elem) => {
                    elem._inputGuests = new InputGuests(elem);
                });

                if(this.params.guests) {
                    $('[name=guests]').val(this.params.guests);
                    $('.input-guests')[0]._inputGuests.elemChange($('.input-guests')[0]._inputGuests);
                }

                $('[name=guests]').on('change', e => {
                    e.preventDefault();
                    const $target = $(e.currentTarget);
        
                    if(! $target.parents('#inquiry').length) {
                        this.params.unitId = $target.attr('data-unitid');
                        this.params.guests = $target.val();
                        if(this.params.guests) {
                            const $unit = $('.unit[data-unitid="' + this.params.unitId + '"]');
                            $unit.find('.add-to-cart').attr('data-guests', this.params.guests);
                        }
            
                        //functions.setUrlData({'dateFrom': this.params.dateFrom, 'guests': this.params.guests }, true, true);
                        this.getCalculation();
                    }
                });

                this.cart = $('.details-cart').length ? new DetailsCart(this.params) : [];

                if(this.params.dateFrom && this.params.dateTo) {
                    let dateFrom = functions.formatDate(this.params.dateFrom);
                    let dateTo = functions.formatDate(this.params.dateTo);
                    $('.unit').each((index, unit) => {
                        const $unit = $(unit);
                        $unit.find('.dates').text(dateFrom + ' - ' + dateTo);
                    });
                }

                this.$units.find('[data-gallery-id]').on('click', function (e) {
                    e.preventDefault();
                    let galleryId = $(this).attr('data-gallery-id');
                    galleryId && showGallery($(this).attr('data-index'), galleryId);
                });

                let now = functions.cleanDate(new Date);

                $('.datepicker-range').each((i, elem) => {
                    const $datepicker = $(elem);
                    const minDate = $datepicker.attr('data-min');
                    const maxDate = $datepicker.attr('data-max');
                    const arrival = $datepicker.attr('data-arrival') ? $datepicker.attr('data-arrival') : [];
                    const defaultDate = $datepicker.attr('data-defaultDate') ? $datepicker.attr('data-defaultDate') : [];
                    const duration = Number($datepicker.attr('data-duration'));
                    $datepicker.flatpickr({
                        mode: "range",
                        showMonths: functions.isMobile() ? 1 : 2,
                        altInput: true,
                        wrap: true,
                        altFormat: "j.m.Y",
                        dateFormat: "Y-m-d",
                        defaultDate: defaultDate,
                        minDate: minDate && new Date(minDate) >= now ? minDate : now,
                        maxDate: maxDate ? maxDate : null,
                        disableMobile: true,
                        prevArrow: '<i class="la la-arrow-circle-left la-fw la-2x pr-2"></i>',
                        nextArrow: '<i class="la la-arrow-circle-right la-fw la-2x pl-2"></i>',
                        locale: {
                            rangeSeparator: ' - '
                        },
                        onDayCreate(dObj, dStr, fp, dayElem){
            
                            let to = new Date(maxDate);
                            let weekday =  new Date(dayElem.dateObj).getDay();
                            weekday = '' + (weekday ? weekday : 7);
                            to.setHours(0, 0, 0, 0);
            
                            if ( dayElem.dateObj > to || (arrival.length && $.inArray( weekday , arrival ) == -1) ) {
                                dayElem.classList.add('unselectable');
                            }
                        }
                    });
                    // to style clear toggler
                    $datepicker.on('change', e => requestAnimationFrame(() => $(e.target).parent().toggleClass('datepicker-value', !!e.target.value)));
                });

                inquiry.init();
                window.nobserver.observe();

                $('[data-bs-toggle="modal"][data-bs-tab]').on('click', e => {
                    const $target = $(e.currentTarget);
                    const unitId = $target.attr('data-unitid');
                    const $tab = $('#unitModal-' + unitId).find('[id=' + $target.attr('data-bs-tab') + ']');
                    $tab.tab('show');
                });
            }

            this.$unitsSpinner.removeClass('active');

            // LOZAD
            window.observer.observe();
        });

    },
    initDepartureCalendar() {
        const $bookingCalendar = $('#booking-calendar-slider');

        if($bookingCalendar[0]) {
            const length = $bookingCalendar.find('.keen-slider__slide').length;
            const perView = 8;
            
            let slidesPerView = length < perView ? length : perView;

            if(length < perView) {
                $('.booking-calendar-wrapper').addClass('col-xl-' + (slidesPerView + 3));
                $('.booking-calendar-wrapper').addClass('mx-auto');
            }
    
            this.slider = new Slider($bookingCalendar, {
                // mode: 'snap',
                //centered: true,
                duration: 200,
                fade: false,
                loop: false,
                controls: false,
                initial: 0,
                arrows: functions.isMobile() || length > slidesPerView,
                slides: {
                    perView: slidesPerView,
                    spacing: 0,
                },
                breakpoints: {
                    '(min-width: 577px) and (max-width: 1024px)': {
                        controls: true,
                        arrows: length > 6,
                        //centered: true,
                        slides: {
                            perView: 6.2,
                        },
                    },
                    '(max-width: 576px)': {
                        controls: true,
                        arrows: length > 2,
                        //centered: true,
                        slides: {
                            perView: 2.2,
                        },
                    },
                },
            });
        }
    },
    setDefaultDeparture() {
        this.params.dateFrom = ! this.params.dateFrom ? $('#booking-calendar-slider [data-datefrom]').attr('data-datefrom') : this.params.dateFrom;

        let $dateFrom = $('#booking-calendar-slider [data-datefrom=' + this.params.dateFrom + ']'); 

        if(! $dateFrom.length) {
            $dateFrom = $('#booking-calendar-slider [data-datefrom]').first();
            this.params.dateFrom = $dateFrom.attr('data-datefrom');
        }

        $dateFrom.addClass('active');
        this.params.dateTo = $dateFrom.attr('data-dateto');

        this.params.guests = $('[name=guests]').val(); 

        //functions.setUrlData({'dateFrom': this.params.dateFrom, 'guests': this.params.guests }, true, true);
        functions.setUrlData({'dateFrom': this.params.dateFrom }, true, true);

        this.printDates();
    },
    setDeparture() {
        if(this.params.dateFrom) {
            $('.departure').removeClass('active');
            $('.departure-sm').removeClass('active');

            let $dateFrom = $('#booking-calendar-slider [data-datefrom=' + this.params.dateFrom + ']');

            if(! $dateFrom.length) {
                $dateFrom = $('#booking-calendar-slider [data-datefrom]').first();
                this.params.dateFrom = $dateFrom.attr('data-datefrom');
            }

            $dateFrom.addClass('active');
            this.params.dateTo = $dateFrom.attr('data-dateto');
            
            this.printDates();
        } else {
            this.setDefaultDeparture();
        }
    },
    printDates() {
        $('.date-from').text(functions.formatDate(this.params.dateFrom));
        $('.date-to').text(functions.formatDate(this.params.dateTo));
    },
    hasUnitRate(unitId, dateFrom) {
        const $departure = $('.departure[data-datefrom=' + dateFrom + '][data-rates]');
        const rates = $departure.attr('data-rates') ? JSON.parse($departure.attr('data-rates')) : null;

        return rates[unitId];
    },
    showUnitsWithRate(dateFrom) {
        // show unit for departure
        const $departure = $('.departure[data-datefrom=' + dateFrom + '][data-rates]');
        const rates = $departure.attr('data-rates') ? JSON.parse($departure.attr('data-rates')) : null;

        let excludedUnits = [];

        if(rates) {
            $('.unit').each((index, elem) => {
                const unitIds = Object.keys(rates);
                const unitId = $(elem).attr('data-unitid');
    
                ! unitIds.includes(unitId) && excludedUnits.push(unitId);
            });
        }

        if(excludedUnits.length) {
            excludedUnits.forEach(unitId => {
                const $unit = $('.unit[data-unitid="' + unitId + '"]');
                $unit.find('.unit-price').addClass('d-none');
                $unit.addClass('d-none');
            });
        }
    },
    setUnitRates() {
        if(this.params.dateFrom) {
            //this.loader();
            this.fadeUnits();

            setTimeout(() => {
                const $departure = $('.departure[data-datefrom=' + this.params.dateFrom + '][data-rates]');
                const rates = $departure.attr('data-rates') ? JSON.parse($departure.attr('data-rates')) : null;

                this.showUnitsWithRate(this.params.dateFrom);

                /*let excludedUnits = [];

                $('.unit').each((index, elem) => {
                    const unitIds = Object.keys(rates);
                    const unitId = $(elem).attr('data-unitid');

                    ! unitIds.includes(unitId) && excludedUnits.push(unitId);
                });

                if(excludedUnits.length) {
                    excludedUnits.forEach(unitId => {
                        const $unit = $('.unit[data-unitid="' + unitId + '"]');
                        $unit.find('.unit-price').addClass('d-none');
                        $unit.addClass('d-none');
                    });
                }*/

                if(rates) {
                    Object.entries(rates).forEach(item => {
                        const unitId = item[0];
                        const rate = item[1];
        
                        const $unit = $('.unit[data-unitid="' + unitId + '"]');
                        //$unit.removeClass('d-none');
    
                        /*let numberOfBeds = $unit.attr('data-numberOfBeds');
                        if(numberOfBeds == this.numberOfBeds || ! this.numberOfBeds) {
                            $unit.removeClass('d-none');
                        } else {
                            $unit.addClass('d-none');
                        }*/
    
                        let numberOfBeds = $unit.attr('data-numberOfBeds');
                        let minPersons = $unit.attr('data-minPersons');
    
                        if(! this.rangeOfPersons || (this.rangeOfPersons >= minPersons && this.rangeOfPersons <= numberOfBeds)) {
                            $unit.removeClass('d-none');
                        } else {
                            $unit.addClass('d-none');
                        }
        
                        $unit.find('.unit-price').removeClass('d-none');
    
                        $unit.find('.unit-price-old').text(rate.priceWithDiscount ? rate.price : '');
                        $unit.find('.unit-price-total').text(rate.priceWithDiscount ? rate.priceWithDiscount : rate.price);
        
                        $unit.find('.fm-discount').toggleClass('d-none', ! rate.discountTitle);
                        $unit.find('.fm-discount-title').text(rate.discountTitle ? rate.discountTitle : '');
                        $unit.find('.fm-discount-valid-until').toggleClass('d-none', ! rate.validUntil);
                        $unit.find('.fm-discount-valid-until-date').text(rate.validUntil ? rate.validUntil : '');
                    });
                }

                if(! $('.unit').not('.d-none').length) {
                    $('.msg-no-units').removeClass('d-none');
                } else {
                    $('.msg-no-units').addClass('d-none');
                }
                
            }, 0);
        }
    },
    setUnitsQuantity() {
        $('.departure').each((index, item) => {
            const $departure = $(item);
            let maxPersonsUnits = $departure.attr('data-maxPersonsUnits') ? JSON.parse($departure.attr('data-maxPersonsUnits')) : null;
            let availableUnits = [];
            
            if(maxPersonsUnits) {
                Object.entries(maxPersonsUnits).forEach(item => {
                    const unitId = item[0];
                    const quantity = item[1];
                    const $unit = $('.unit[data-unitid="' + unitId + '"]');
                    const isZeroNights = $unit.attr('data-isZeroNights');
                    const isExcursion = $unit.attr('data-isExcursion');
                    
                    const unitMaxPersons = $unit.attr('data-maxpersons');
                    const unitsQuantity = isZeroNights || isExcursion ? (+quantity) : Math.round((+quantity) / (+unitMaxPersons));

                    availableUnits.push({'unitId': unitId, 'quantity': unitsQuantity, 'tempQuantity': unitsQuantity});
                });
    
                $departure.attr('data-availbleUnits', JSON.stringify(availableUnits));
            }
        });
    },
    setUnitQuantityForDate() {
        const $departure = $('.departure[data-datefrom=' + this.params.dateFrom + ']');
        let availbleUnits = $departure.attr('data-availbleUnits') ? JSON.parse($departure.attr('data-availbleUnits')) : null;
        
        if(availbleUnits) {
            Object.entries(availbleUnits).forEach(item => {
                item = item[1];
                const unitId = item.unitId;
                const quantity = item.quantity;
                let tempQuantity = item.tempQuantity;

                const $unit = $('.unit[data-unitid="' + unitId + '"]');
                const $unitGuestsForm = $unit.find('.unit-guests-form');

                $unit.find('.available-units-count').text(tempQuantity);

                if(! tempQuantity) {
                    $unitGuestsForm.addClass('d-none');
                    $unit.find('.available-units').addClass('d-none');
                    $unit.find('.no-period-label').addClass('d-none');
                    $unit.find('.unit-error-container').removeClass('d-none').text('Prodato!');
                } else {
                    $unitGuestsForm.removeClass('d-none');
                    $unit.find('.available-units').removeClass('d-none');
                    $unit.find('.no-period-label').removeClass('d-none');
                    $unit.find('.unit-error-container').addClass('d-none');
                }

                if(tempQuantity > 3) {
                    $unit.find('.available-units').addClass('d-none');
                }

                if(! quantity) {
                    $unit.prop('style', 'order: 10000;');
                } else {
                    $unit.prop('style', 'order: ' + $unit.attr('data-order') + ';');
                }
            });
        }
    },
    getParams() {
        this.params = details.getParams(this.params);
    },
    getCalculation() {
        
        const calculation = details.getCalculation(this.params);
        //this.setUnitRates();
        if(calculation) {
            //this.loader(this.params.unitId ? $('.unit[data-unitid=' + this.params.unitId + ']').find('.unit-spinner') : null);
            this.loadSpinner(this.params.unitId ? $('.unit[data-unitid=' + this.params.unitId + ']').find('.unit-spinner') : null);
            calculation
                .done(response => {
                    this.calculationDone(response);
                }).fail(error => {
                    console.log(error);
                });
        } else {

            //this.setPrices($('.departure[data-datefrom=' + this.params.dateFrom + '][data-dateto=' + this.params.dateTo + ']'));

            this.setUnitRates();
            
            $('.send-request-link').toggleClass('d-none', true);
            $('.add-to-cart').toggleClass('d-none', true);

            //$('.unit-error-container').toggleClass('d-none', true);

            $('.unit-price-payment-key').toggleClass('d-none', false);
        }
    },
    calculationDone(response) {

        if(response.status) {
            const data = response.data || [];

            Object.entries(data).forEach(unit => {
                const unitId = unit[0];
                const unitData = unit[1];
                const unitCalc = unitData.calc;
                const $unit = $('.unit[data-unitid=' + unitId + ']');
                const minPersons = +$unit.attr('data-minpersons');
                const isExcursion = $unit.attr('data-isexcursion');
                let maxPersons = +$unit.attr('data-maxpersons');
                const $addToCart = $unit.find('.add-to-cart');
                const $departure = $('.departure[data-datefrom=' + this.params.dateFrom + ']');

                if(! maxPersons) {
                    let maxPersonsUnits = $departure.length && $departure.attr('data-maxPersonsUnits') ? JSON.parse($departure.attr('data-maxPersonsUnits')) : null;
                    maxPersons = maxPersonsUnits && maxPersonsUnits[unitId] ? maxPersonsUnits[unitId] : 9;
                }
                
                let discountTitle = null;
                let persons = unitCalc.guests.split(',');
                persons = +persons[0] + +persons[1];
                let isBookable = isExcursion ? details.isBookable(unitData) : details.isBookable(unitData) && persons >= minPersons && persons <= maxPersons;

                let availbleUnits = $departure.attr('data-availbleUnits') ? JSON.parse($departure.attr('data-availbleUnits')) : null;
                
                let unitQuantity = availbleUnits && availbleUnits.filter(item => {
                    return item.unitId == unitId;
                })[0];
                isBookable = unitQuantity && ! unitQuantity.tempQuantity ? false : isBookable;

                $unit.removeClass('d-none');
                this.showUnitsWithRate(this.params.dateFrom);

                let hasUnitRate = this.hasUnitRate(unitId, this.params.dateFrom);
                
                if(hasUnitRate) {
                    let numberOfBeds = $unit.attr('data-numberOfBeds');                    

                    if(! this.rangeOfPersons || (this.rangeOfPersons >= minPersons && this.rangeOfPersons <= numberOfBeds)) {
                        $unit.removeClass('d-none');
                    } else {
                        $unit.addClass('d-none');
                    }

                    /*if(numberOfBeds == this.numberOfBeds || ! this.numberOfBeds) {
                        $unit.removeClass('d-none');
                    } else {
                        $unit.addClass('d-none');
                    }*/
                }

                if(unitCalc && unitCalc.total) {
                    const calcItems = unitCalc.items;
                    const currencySymbol = unitCalc.currencySymbol;
                    
                    let total = unitCalc.total;
                    total = isExcursion ? total : total / persons;

                    let priceOld = details.getCalcTotalOld(calcItems);
                    priceOld = +priceOld.toFixed(2);
                    priceOld = isExcursion ? priceOld : priceOld / persons;

                    discountTitle = details.getCalcDiscountTitle(calcItems);
    
                    if(discountTitle) {
                        $unit.find('.unit-price-discount-title').text(discountTitle);
                        $unit.find('.fm-discount-title').text(discountTitle ? discountTitle : '');
                    }

                    $unit.find('.unit-price-old').text(priceOld > total ? currencySymbol + ' ' + functions.formatMoney(priceOld, 0, '.', ',') : '');
                    isExcursion && $unit.find('.unit-price-total-label').removeClass('d-none');
                    $unit.find('.unit-price-total').text(currencySymbol + ' ' + functions.formatMoney(total, 0, '.', ','));

                    if(isBookable) {
                        //const calcItems = unitCalc.items;
                        //const total = unitCalc.total;
                        //const currencySymbol = unitCalc.currencySymbol;
    
                        //let priceOld = details.getCalcTotalOld(calcItems);
                        //priceOld = +priceOld.toFixed(2);
                        //discountTitle = details.getCalcDiscountTitle(calcItems);
    
                        //if(discountTitle) {
                        //    $unit.find('.unit-price-discount-title').text(discountTitle);
                        //    $unit.find('.fm-discount-title').text(discountTitle ? discountTitle : '');
                        //}
    
                        //$unit.find('.unit-price-old').text(priceOld > total ? currencySymbol + ' ' + functions.formatMoney(priceOld, 0, '.', ',') : '');
                        //$unit.find('.unit-price-total-label').removeClass('d-none');
                        //$unit.find('.unit-price-total').text(currencySymbol + ' ' + functions.formatMoney(total, 0, '.', ','));

                        //if(isExcursion) {
                        //}
    
                        //$unit.prop('style', 'order: ' + total);

                        $addToCart.attr('data-unitcarttotal', total);
                        $addToCart.attr('data-unitcarttotalold', priceOld);
                        $addToCart.attr('data-currencysymbol', currencySymbol);

                        $addToCart.attr('data-datefrom', this.params.dateFrom);
                        $addToCart.attr('data-dateto', this.params.dateTo);
                        //$addToCart.attr('data-guests', this.params.guests);
    
                        //const $btnBooking = $('.btn-booking[data-unitid=' + unitId + ']');
                        const $btnBooking = $('.btn-booking');

                        $btnBooking.on('click', e => {
                            e.preventDefault();
                            let cartData = [];
    
                            this.cart.cartItems.forEach(item => {
                                item = JSON.parse(item);
                                cartData.push({
                                    'unitId': item.unitId,
                                    'dateFrom': item.dateFrom,
                                    'dateTo': item.dateTo,
                                    'guests': item.guests,
                                }); 
                            });
    
                            let url = $(e.currentTarget).attr('data-href') 
                                + '?cart_data=' + JSON.stringify(cartData) 
                                + '&addAllOptionals=true' 
                                + '&partnerId=' + this.params.partnerId
                                + (this.params.currencyIdTo ? '&currencyIdTo=' + this.params.currencyIdTo : '') 
                                + (this.params.hide !== null ? '&hide=' + this.params.hide : '')
                                + (this.params.calcPP !== null ? '&calcPP=' + this.params.calcPP : '');

                            if(this.cart.cartItems.length) {
                                location = url;
                            } else {
                                $('#detailsCartModal').modal('show');
                                setTimeout(() => {
                                    $('#detailsCartModal').modal('hide');
                                }, 2500);
                            }
                        });
                        
                    } else {
                        //$unit.prop('style', 'order: 10000;');
                    }
                }

                if(! isBookable) {
                    //$unit.prop('style', 'order: 10000;');
                }
                
                //$unit.find('.unit-price-total-label').toggleClass('d-none', ! details.isBookable(unitData));
                //$unit.find('.unit-price').toggleClass('d-none', ! details.isBookable(unitData));

                $unit.find('.unit-price-discount-title').toggleClass('d-none', ! isBookable);
                $unit.find('.unit-price-payment-key').toggleClass('d-none', isBookable);

                $unit.find('.fm-discount').toggleClass('d-none', ! discountTitle || ! isBookable);                
                $unit.find('.fm-discount-valid-until').toggleClass('d-none', isBookable);

                //$unit.find('.btn-booking').toggleClass('d-none', ! isBookable);
                $unit.find('.send-request-link').toggleClass('d-none', ! isBookable);

                $addToCart.length && $addToCart.toggleClass('d-none', !isBookable);

                let error = details.getCalcErrorMessage(this.params.calcErrorMessages, unitData);
                if(persons < minPersons) {
                    error = 'Minimalan broj osoba je ' + minPersons + '.';
                } else if(persons > maxPersons) {
                    error = 'Maksimalan broj osoba je ' + maxPersons + '.';
                }
                
                $unit.find('.unit-error-container').toggleClass('d-none', isBookable);
                $unit.find('.unit-error-container').text(error);

                $unit.find('.no-period-label').toggleClass('d-none', unitData.message == 'notAvailable' || isBookable);
                isExcursion && $unit.find('.unit-price-label').toggleClass('d-none', unitData.message == 'notAvailable' || details.isBookable(unitData));

                if(! isBookable) {
                    $unit.find('.no-period-label').addClass('d-none');
                }
            });

        }
    },
    fadeUnits() {
        $('.units').addClass('fade');
        this.$unitsSpinner.addClass('active');
        setTimeout(() => {
            $('.units').removeClass('fade');
            this.$unitsSpinner.removeClass('active');
        }, 800);
    },
    loadSpinner($target = null) {
        $target ? $target.addClass('active') : $('.unit-spinner').addClass('active');
        setTimeout(() => {
            $target ? $target.removeClass('active') : $('.unit-spinner').removeClass('active');
        }, 600);
    },
    /*loader($target = null) {
        //$('.units').addClass('fade');
        $target ? $target.addClass('active') : $('.unit-spinner').addClass('active');
        setTimeout(() => {
            $target ? $target.removeClass('active') : $('.unit-spinner').removeClass('active');
        }, 600);
        setTimeout(() => {
            //$('.units').removeClass('fade');
        }, 800);
    }*/
};
