import Slider from "./components/Slider";
import "./components/datepicker.js";
import {DeparturePicker} from "./components/departure-picker.js";
import inputPopover from "./components/input-popover.js";
import {FormGuide, FormGuideDefaults} from "./components/form-guide";
import {InputGuests} from "./components/input-guests";
import functions from "./functions";
import { Select2 } from "./components/select2";

$(function () {

  $('.btn-level').on('click', e => {
    e.preventDefault();
    e.stopPropagation();
    const $target = $(e.currentTarget);
    $target.toggleClass('show', ! $target.hasClass('show'));
    
    const $sibling = $target.siblings('.dropdown-submenu');
    $sibling.toggleClass('show', ! $sibling.hasClass('show'));
  })

  $('#navMainMenu').on('show.bs.collapse', e => {
    $('.navbar').addClass('show');
  });
  $('#navMainMenu').on('hide.bs.collapse', e => {
    $('.navbar').removeClass('show');
  });

  $(".select2").each((i, elem) => {
    elem._select2 = new Select2(elem);
  });
  
  $('.form-guide').each((i, elem) => {
    elem._formGuide = new FormGuide(elem);
  });

  $('.datepicker.departure-picker [data-input]').each((i, elem) => {
    elem._departurePicker = new DeparturePicker(elem);
  });

  $('.input-guests').each((i, elem) => {
    elem._inputGuests = new InputGuests(elem);
  });

  inputPopover.apply();

  $('.search-form label').on('click', e => {
    const $target = $(e.currentTarget);
    const $elem = $target.find('.select2-search__field');
    $elem[0] && $elem.focus();
  });

  $(document).on('click', '[data-scroll-to]', e => {
    e.preventDefault();
    const $this = $(e.target);
    let scrollTo = $this.attr('data-scroll-to').split(',');
    functions.scrollToElement(scrollTo[0], scrollTo[1] || 0);
  });

  $('#collapseItinerary').on('show.bs.collapse', e => {
      $('.show-itinerary').addClass('d-none');
  });
  $('#collapseItinerary').on('hide.bs.collapse', e => {
      $('.show-itinerary').removeClass('d-none');
  });

  if($('#hero-slider')[0]) {
    new Slider($('#hero-slider'), {
      rubberband: false,
      loop: true,
      arrows: true,
      pager: false,
      autoplay: 10000,
      slides: {
        perView: 1,
        spacing: 0,
      },
      defaultAnimation: {
        duration: 1200,
      },
    });
  }

  let objectsSlider = [];

  if($('[id^=objects-slider]')[0]) {
    $('[id^=objects-slider]').each((i, elem) => {
      objectsSlider[i] = new Slider('#' + $(elem).attr('id'), {
        rubberband: false,
        loop: true,
        arrows: true,
        pager: true,
        slides: {
          perView: 4,
          spacing: 0,
        },
        defaultAnimation: {
          duration: 1200,
        },
        breakpoints: {
          '(max-width: 1390px)': {
            slides: {
              perView: 3,
            },
          },
          '(max-width: 768px)': {
            slides: {
              perView: 2.2,
            },
          },
          '(max-width: 560px)': {
            slides: {
              perView: 1.2,
            },
          },
        }
      });
    });
  }

  if(functions.getCookie('cookie_usage') !== 'accepted') {
    $("#cookie-bar").removeClass('d-none');
  }

  $('#cookie-bar-accept').on('click', function (e) {
    functions.setCookie('cookie_usage', 'accepted');
    $("#cookie-bar").addClass('d-none');
  });

});