import functions from '../../functions';

if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        RichText,
        InspectorControls,
        MediaUpload,
    } = window.wp.editor;
    
    const { 
        PanelBody, 
        IconButton, 
        TextControl,
        TextareaControl,
    } = window.wp.components;

    registerBlockType('custom-gutenberg/card-video', {
        // built-in attributes
        title: 'Card Video',
        description: 'Custom Card Video',
        icon: '',
        category: 'custom-cards',
        example: {},

        // custom attributes
        attributes: {
            link: {
                type: 'string',
                default: '',
            },
            image: {
                type: 'string',
                default: ''
            }, 
            alt: {
                type: 'string',
                default: ''
            }, 
            title: {
                type: 'string',
                default: ''
            },
            description: {
                type: 'string',
                default: ''
            },
        },

        // built-in functions
        edit({ attributes, setAttributes, className }) {

            const {
                link,
                image,
                alt,
                title,
                description,
                classes
            } = attributes;

            className = functions.getClassNames(className);
            if(classes != className) setAttributes({classes: className});

            // custom functions
            function onLinkChange (link) {
                setAttributes({link: link});
            };

            function onSelectImage (image) {
                setAttributes({image: image.sizes.full.url});
            }

            function onAltChange(alt) {
                setAttributes({alt: alt});
            }

            function onTitleChange(title) {
                setAttributes({title: title});
            }

            function onDescriptionChange(description) {
                setAttributes({description: description});
            }

            return ([
                <InspectorControls style={ { marginBottom: '40px' } }>
                    <PanelBody title={ 'Settings' }>
                        <MediaUpload 
                            onSelect={ onSelectImage }
                            type="image"
                            value={ image } 
                            render={ ( { open } ) => (
                                <IconButton
                                    onClick={ open }
                                    icon="upload"
                                    classNames="editor-media-placeholder__button is-button is-default is-large">
                                    Select Background Image
                                </IconButton>
                            ) } />
                        <br />
                        <p><strong>Alt</strong></p>
                        <TextControl value={ alt } onChange={ onAltChange }></TextControl>
                        <br />
                        <p><strong>Enter url video</strong></p>
                        <TextControl value={ link } onChange={ onLinkChange }></TextControl>
                        <br />
                        <p><strong>Title</strong></p>
                        <TextControl value={ title } onChange={ onTitleChange }></TextControl>
                        <br />
                        <p><strong>Description</strong></p>
                        <TextareaControl value={ description } onChange={ onDescriptionChange }></TextareaControl>
                    </PanelBody>
                </InspectorControls>
                ,
                <div class={ "card-video " + classes }>
                    <iframe class="video" src={ link } title={ alt } frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                    <RichText 
                        key="editable" 
                        tagName="h6"
                        className="title"
                        placeholder="Title" 
                        value={ title }
                        onChange={ onTitleChange } />
                    <RichText 
                        key="editable" 
                        tagName="p"
                        className="description"
                        placeholder="Description" 
                        value={ description }
                        onChange={ onDescriptionChange } />
                </div>
            ]
            );
        },

        save({ attributes }) {

            const {
                link,
                image,
                alt,
                title,
                description,
                classes
            } = attributes;

            return (
                <div class={ "card-video" + (classes ? ' ' + classes : '') }>
                    <iframe class="video lozad" data-src={ link } title={ alt } frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                    <h6 class="title">{ title }</h6>
                    <p class="description">{ description }</p>
                </div>
            );
        },
    });
}