import functions from '../functions.js';

$(function () {
    // global datepicker init with automatic "dateTo" set if provided
    let displayDate = new Date('2019-06-01');
    let now = functions.cleanDate(new Date);
    if ( now > displayDate ) {
        displayDate = now;
    }

    $('.datepicker-range').each((i, elem) => {
        const $datepicker = $(elem);
        const minDate = $datepicker.attr('data-min');
        const maxDate = $datepicker.attr('data-max');
        const arrival = $datepicker.attr('data-arrival') ? $datepicker.attr('data-arrival') : [];
        const defaultDate = $datepicker.attr('data-defaultDate') ? $datepicker.attr('data-defaultDate') : [];
        const duration = Number($datepicker.attr('data-duration'));
        $datepicker.flatpickr({
            mode: "range",
            showMonths: functions.isMobile() ? 1 : 2,
            altInput: true,
            wrap: true,
            altFormat: "j.m.Y",
            dateFormat: "Y-m-d",
            defaultDate: defaultDate,
            minDate: minDate && new Date(minDate) >= now ? minDate : now,
            maxDate: maxDate ? maxDate : null,
            disableMobile: true,
            prevArrow: '<i class="la la-arrow-circle-left la-fw la-2x pr-2"></i>',
            nextArrow: '<i class="la la-arrow-circle-right la-fw la-2x pl-2"></i>',
            locale: {
                rangeSeparator: ' - '
            },
            onDayCreate(dObj, dStr, fp, dayElem){

                let to = new Date(maxDate);
                let weekday =  new Date(dayElem.dateObj).getDay();
                weekday = '' + (weekday ? weekday : 7);
                to.setHours(0, 0, 0, 0);

                if ( dayElem.dateObj > to || (arrival.length && $.inArray( weekday , arrival ) == -1) ) {
                    dayElem.classList.add('unselectable');
                }
            }
        });
        // to style clear toggler
        $datepicker.on('change', e => requestAnimationFrame(() => $(e.target).parent().toggleClass('datepicker-value', !!e.target.value)));
    });

    $('.datepicker').not('.departure-picker').each((i, elem) => {
        const $datepicker = $(elem);
        const minDate = $datepicker.attr('data-min');
        const maxDate = $datepicker.attr('data-max');
        $datepicker.flatpickr({
            altInput: true,
            wrap: true,
            altFormat: "j.m.Y",
            dateFormat: "Y-m-d",
            minDate: minDate ? minDate : now,
            maxDate: maxDate ? maxDate : null,
            disableMobile: true,
            prevArrow: '<i class="la la-angle-left pr-2"></i>',
            nextArrow: '<i class="la la-angle-right pl-2"></i>',
            onChange(selectedDates, dateStr, instance) {

                const $first = $(instance.input);
                // prevent other logic if second datepicker is triggered by first
                if (instance._openByFirst) {
                    delete instance._openByFirst;
                    return;
                }
                
                if ($first.attr('data-firstDate')) {
                    const $second = $first.closest('form').find('[data-secondDate]').closest('.datepicker');
                    if ($second.length) {

                        var firstDate = new Date(selectedDates[0]);
                        firstDate.setDate(firstDate.getDate() + 1);
                        
                        var secondInstance = $second[0]._flatpickr;
                        // check if firstDate is invalid
                        if (firstDate.getTime() === firstDate.getTime()) {
                            secondInstance._openByFirst = true;
                            secondInstance.set('minDate', firstDate);
                            if (!secondInstance.selectedDates[0]) {
                                firstDate.setDate(firstDate.getDate() + 6);
                                secondInstance.setDate(firstDate);
                            }
                            secondInstance.open();
                            $(secondInstance.altInput).focus();
                        }
                        else if (secondInstance.selectedDates[0]) {
                            secondInstance.clear();
                        }
                    }
                }
                // check if second date is cleared
                else if ($first.attr('data-secondDate') && !selectedDates[0]) {
                    $first.closest('form').find('[data-firstDate]').closest('.datepicker')[0]._flatpickr.clear();
                }
            },
            onOpen(selectedDates, dateStr, instance) {
                if (instance.input.getAttribute('data-secondDate')) {
                    const $first = $(instance.input).closest('form').find('[data-firstDate]').closest('.datepicker');
                    if ($first.length) {
                        const firstInstance = $first[0]._flatpickr;
                        if (!firstInstance.input.value) {
                            instance.close();
                            firstInstance.open();
                            firstInstance.altInput.focus();
                            return;
                        }
                    }
                }
                !dateStr && instance.jumpToDate(minDate ? minDate : displayDate);
            }
        });
        // to style clear toggler
        $datepicker.on('change', e => requestAnimationFrame(() => $(e.target).parent().toggleClass('datepicker-value', !!e.target.value)));
    }); 
});