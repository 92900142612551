import './details/details';
//import './details/details-package';
import './details/details-accommodation';
import './details/details-travel';
import functions from './functions';

$(() => {
    
    $('[data-bs-toggle="modal"][data-bs-tab]').on('click', e => {
        const $target = $(e.currentTarget);
        const unitId = $target.attr('data-unitid');
        const $tab = $('#unitModal-' + unitId).find('[id=' + $target.attr('data-bs-tab') + ']');
        $tab.tab('show');
    });

    $('.summary-large-show').on('click', e => {
        const $target = $(e.currentTarget);
        const $summary = $('.summary-large');
        const isShowen = $summary.hasClass('show');
        $summary.toggleClass('show', ! isShowen);
        $target.text(isShowen ? $target.attr('data-show') : $target.attr('data-hide'));
    });

    const $detailsNav = $('#details-nav');
    const detailsNav = $detailsNav[0];

    if(detailsNav) {
        $(window).on('scroll', e => {
            $detailsNav.toggleClass('shadow', detailsNav.getBoundingClientRect().top === 0);
        });
    }

    if($('.add-table-fixed').length) {
        const isMobile = functions.isMobile();
        let noColumns = isMobile ? 2 : 7;

        $('#pricelistTableModal, [id^=unitModal-]').on('shown.bs.modal', e => {
            const $target = $(e.currentTarget);
            const $fixedTableContainer = $target.find('.add-table-fixed');
            let isFixed = $fixedTableContainer.find('.table-fixed').length;

            if(! isFixed) {
                const $table = $fixedTableContainer.find('table');
                let fixTable = $table.length && $table.find('thead th').length > noColumns;
                fixTable = isMobile ? fixTable && $table.width() > (screen.width - 32) : fixTable;

                if(fixTable) {
                    const $tableFixed = $table.clone();
                    $tableFixed.addClass('table-fixed');
                    $fixedTableContainer.append($tableFixed);
                    $table.find('th').css('border-width', '0');
                }
            }
        });


    }

});