import functions from '../../functions';

if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        RichText,
        InspectorControls,
        MediaUpload,
    } = window.wp.editor;
    
    const { 
        PanelBody, 
        IconButton, 
        TextControl
    } = window.wp.components;

    registerBlockType('custom-gutenberg/partner-link', {
        // built-in attributes
        title: 'Partner Link',
        description: 'Custom Partner Link',
        icon: '',
        category: 'custom-components',
        example: {},

        // custom attributes
        attributes: {
            link: {
                type: 'string',
                default: '',
            },
            image: {
                type: 'string',
                default: ''
            }, 
            alt: {
                type: 'string',
                default: ''
            }, 
            title: {
                type: 'string',
                default: ''
            },
        },

        // built-in functions
        edit({ attributes, setAttributes, className }) {

            const {
                link,
                image,
                alt,
                title,
                classes
            } = attributes;

            className = functions.getClassNames(className);
            if(classes != className) setAttributes({classes: className});

            // custom functions
            function onLinkChange (link) {
                setAttributes({link: link});
            };

            function onSelectImage (image) {
                setAttributes({image: image.sizes.full.url});
            }

            function onAltChange(alt) {
                setAttributes({alt: alt});
            }

            function onTitleChange(title) {
                setAttributes({title: title});
            }

            return ([
                <InspectorControls style={ { marginBottom: '40px' } }>
                    <PanelBody title={ 'Settings' }>
                        <MediaUpload 
                            onSelect={ onSelectImage }
                            type="image"
                            value={ image } 
                            render={ ( { open } ) => (
                                <IconButton
                                    onClick={ open }
                                    icon="upload"
                                    classNames="editor-media-placeholder__button is-button is-default is-large">
                                    Select Background Image
                                </IconButton>
                            ) } />
                        <br />
                        <p><strong>Alt image:</strong></p>
                        <TextControl value={ alt } onChange={ onAltChange }></TextControl>
                        <br />
                        <p><strong>Enter url: (ex: /putovanja)</strong></p>
                        <TextControl value={ link } onChange={ onLinkChange }></TextControl>
                        <br />
                        <p><strong>Title</strong></p>
                        <TextControl value={ title } onChange={ onTitleChange }></TextControl>

                    </PanelBody>
                </InspectorControls>
                ,
                <a class={ "partner-link " + classes }>
                    <div class="img-top">
                        <img src={ image } alt={ alt } />
                    </div>
                    <RichText 
                        key="editable" 
                        tagName="div"
                        placeholder="Title" 
                        value={ title }
                        onChange={ onTitleChange } />
                </a>
            ]
            );
        },

        save({ attributes }) {

            const {
                link,
                image,
                alt,
                title,
                classes
            } = attributes;

            return (
                <a href={ link } target="_blank" class={ "partner-link " + (classes ? ' ' + classes : '') }>
                    <div class="img-top">
                        <img class="lozad" data-src={ image } alt={ alt } />
                    </div>
                    <div>{ title }</div>
                </a>
            );
        },
    });
}