import functions from '../functions';
import Slider from "../components/Slider";

$(() => {
    details.init();
})

const details = {
    $details: null,
    $form: null,
    form: null,
    params: {
        objectId: null, 
        unitId: null, 
        guests: null, 
        dates: null,
        dateFrom: null, 
        dateTo: null, 
        objectGroupId: null,
        calcErrorMessages: null,
    },
    init() {

        details.$details = $('.page-type-details');

        if(! details.$details[0]) {
            return;
        }

        // scroll by swipe
        const $itineraryDaySummary = $('.itinerary-day-summary');
        if($itineraryDaySummary.length) {
            let width = 0;
            $('.itinerary-day-slide').each((index, slide) => {
                width += slide.clientWidth;
            });
        
            if($itineraryDaySummary[0].clientWidth > width) {
                $itineraryDaySummary.addClass('justify-content-end');
            } else {
                $itineraryDaySummary.removeClass('justify-content-end');
                $itineraryDaySummary[0].scrollTo({
                    left: -width,
                    behavior: 'smooth'
                });
            }
        
            $itineraryDaySummary.on('mousedown mousemove mouseup touchstart touchmove touchend', e => {
                
                const $target = $(e.currentTarget);
                const dir = functions.getSwipeDirection(e);
                
                if(dir != 0) {
                    $target[0].scrollTo({
                        left: $target[0].scrollLeft + ($target[0].clientWidth * dir),
                        behavior: 'smooth'
                    });
                }
        
            });
        }

        const $detailsNavSlider = $('#details-nav-slider');
        if($detailsNavSlider.length) {
            new Slider($detailsNavSlider, {
              rubberband: false,
              loop: false,
              arrows: false,
              pager: false,
              slides: {
                perView: 'auto',
                spacing: 0,
              },
              defaultAnimation: {
                duration: 1200,
              },
            });
        }
    },
    getParams(params, defaults = {}) {
        return $.extend({}, params, defaults, functions.getUrlData(true));
    },
    isBookable(data, rateId = null) {
        if(rateId) {
            return (data.booking.status == 'Request' || data.booking.status == 'Reservation') && data.isAvailable && data.calc.status == 'Active';
        }
        return data.booking.status == 'Request' || data.booking.status == 'Reservation';
    },
    getCalcErrorMessage(messages, data) {
        if (data.message && messages && messages[data.message]) {
            return messages[data.message].replace('{$condition}', data.condition);
        }
        return messages ? messages.general : null;
    },
    getCalculation(params) {

        const objectGroupId = params.objectGroupId;
        const objectId = params.objectId;
        const unitId = params.unitId;
        const dateFrom = params.dateFrom;
        const dateTo = params.dateTo ? params.dateTo : dateFrom;
        const guests = params.guests && params.guests.startsWith('0') ? null : params.guests;
        const dynamicPackage = params.dynamicPackage;
        const flightId = params.flightId;
        const flightUnitId = params.flightUnitId;
        const partnerId = params.partnerId;
        const currencyIdFrom = params.currencyIdFrom;
        const currencyIdTo = params.currencyIdTo;

        if(
            (objectId || unitId)
            && guests 
            && dateFrom 
            && dateTo 
            && objectGroupId
        ) {
            return $.getJSON(
                location.protocol
                + '//'
                + location.hostname
                + '/services/calc/'
                + (unitId ? '?unitId=' + unitId : '?publicId=' + objectId)
                + '&guests=' + guests
                + '&dateFrom=' + dateFrom
                + '&dateTo=' + dateTo
                + '&objectGroupId=' + objectGroupId
                + '&language=' + functions.getLang()
                + (partnerId ? '&partnerId=' + partnerId : '')
                + (dynamicPackage ? '&dynamicPackage=' + dynamicPackage : '')
                + (flightUnitId ? '&flightUnitId=' + flightUnitId : '') 
                + (currencyIdFrom ? '&currencyIdFrom=' + currencyIdFrom : '')
                + (currencyIdTo ? '&currencyIdTo=' + currencyIdTo : '')
            );
        } else {
            return null;
        }

    },
    getCalcTotalOld(calcItems) {
        let priceOld = 0;

        calcItems.forEach(item => {
            // popust za djecu ne ulazi u prikaz discount cijene
            if((item.itemType != 'discount' || item.itemDefinitionId == 129) && !item.optional && !item.onSpot) {
                priceOld += item.itemTotal;
            }
        });

        return priceOld;
    },
    getCalcDiscountTitle(calcItems) {

        let discountTitle = null

        calcItems.forEach(item => {
            if(item.itemType == 'discount') {
                discountTitle = item.itemTitle;
                if(item.data && item.data.text) {
                    discountTitle = item.data.text;
                }
            }
        });
        
        return discountTitle;
    },
};

export {
    details,
};
