const getFormData = $form => {
    const data = {};
    $.map($form.serializeArray(), o => {
        data[o.name] = data[o.name] ? (data[o.name] + ',' + o.value) : o.value;
    });
    return data;
};

const getUrlData = fromHash => {
    const data = {};
    let search = fromHash ? location.hash.replace('#', '') : location.search.replace('?', '');
    search.indexOf('&') > -1 && (search = search.split('&'));
    !Array.isArray(search) && (search = [search]);
    if ( search.length === 1 && search[0] === '' ) {
        return data;
    }
    $.map(search, param => data[decodeURIComponent(param.split('=')[0])] = decodeURIComponent(param.split('=')[1]));
    return data;
};

const cleanDate = (date) => {
    date = new Date(date);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
}

const setFormData = (form, data) => {
    $.map(data, (val, name) => {
        const elem = form[name];
        
        if ( !elem ) {
            return;
        }
        const $elem = $(elem);

        if($elem.attr('name') == 'dateFrom') {
            elem._flatpickr.setDate(val);
        }
        if($elem.attr('name') == 'dates') {
            let dates = val ? val.split(' - ') : [];

            const date = $elem.closest('.datepicker,.datepicker-range')[0]

            date._flatpickr.clear();
            if(dates[0] && dates[1]) {
                date._flatpickr.setDate([dates[0],dates[1]]);
            } else if(dates[0]) {
                date._flatpickr.setDate([dates[0],dates[0]]);
            }
        }

        if ( elem.type === 'checkbox' ) {
            elem.checked = !!val;
        }
        else if ( elem[0] && elem[0].type === 'checkbox' ) {
            val = $.isArray(val) ? val : (val || '').split(',');
            elem.forEach(elem => {
                elem.checked = val.indexOf(elem.value) > -1;
            });
        }
        else if ( elem[0] && elem[0].type === 'radio' ) {
            elem.forEach(elem => {
                elem.checked = elem.value == val;
            });
        }
        else {
            $elem.val(val);
        }
        $elem.trigger('change');
    });
};

const _replaceState = history.replaceState;
history.replaceState = function () {
    const retVal = _replaceState.apply(history, arguments);
    const e = new Event('hashchange');
    e.arguments = arguments;
    window.dispatchEvent(e);
    return retVal;
};
let setUrlTimeout = 0;
const setUrlData = (data, toHash, replace) => {
    clearTimeout(setUrlTimeout);
    setUrlTimeout = setTimeout(() => {
        $.map(data, (value, key) => !value && (delete data[key]));
        if ( toHash ) {
            let newHash = $.param(data);
            let oldHash = location.hash.replace('#', '');
            if ( newHash !== oldHash ) {
                if ( replace ) {
                    history.replaceState('', '', '#' + newHash);
                } else {
                    location.hash = newHash;
                }
            }
        } else {
            location.search = $.param(data);
        }
    }, 100);
};

const getDataForUrl = (data) => {
    $.map(data, (value, key) => !value && (delete data[key]));
    if (data.dates) {
        let dates = data.dates.split(' - ');
        data.dateFrom = dates[0];
        data.dateTo = dates[1];
        delete data.dates;
    }

    return data;

};

const getLang = () => {
    return (document.documentElement.lang || '').split('-')[0] || 'hr';
};

const $animateSelector = $('html, body');
const scrollToElement = ($elem, offset) => {
    $elem = $($elem);
    $elem.length && $animateSelector.animate({
        scrollTop: $elem.offset().top - (offset || 0)
    });
};

const smallWin = () => {
    return window.innerWidth < 992;
}

const formatMoney = (n, c, d, t) => {
    var c = isNaN(c = Math.abs(c)) ? 2 : c,
        d = d == undefined ? "." : d,
        t = t == undefined ? "," : t,
        s = n < 0 ? "-" : "",
        i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
        j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};

const formatDate = (date) => {
    date = new Date(date);
    return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + '.';
};

const setCookie = (key, value, expires = null) => {
    let mili = expires ? expires * 24 * 60 * 60 * 1000 : 365 * 24 * 60 * 60 * 1000;
    expires = new Date();
    expires.setTime(expires.getTime() + mili);
    document.cookie = key + '=' + value + ';expires=' + expires.toUTCString() + '; path=/';
};

const getCookie = (key) => {
    let keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    return keyValue ? keyValue[2] : null;
};

const isObject = obj => {
    return obj === Object(obj);
};

const isMobile = () => {
    return screen.width < 992;
};

const swipeTreshold = 40; // min difference to consider action as swiping
let swipeTimeout = 0;
let swipeStartX = 0;
let swipeMoveX = 0;

const getSwipeDirection = e => {
	if ( e.type == 'touchstart' || e.type == 'mousedown' ) {
		swipeStartX = e.touches ? e.touches[0].clientX : e.clientX;
		return 0;
	}
	if ( e.type == 'touchmove' || e.type == 'mousemove' ) {
		swipeMoveX = e.touches ? e.touches[0].clientX : e.clientX;
		return 0;
	}
	if ( e.type == 'touchend' || e.type == 'mouseup' ) {
		let swipeDiff = swipeMoveX > 0 ? Math.abs(swipeStartX - swipeMoveX) : 0;
		return swipeDiff > swipeTreshold ? (swipeMoveX > swipeStartX ? -1 : 1) : 0;
	}
};

module.exports = {
    cleanDate: cleanDate,
    getFormData: getFormData,
    getUrlData: getUrlData,
    setFormData: setFormData,
    setUrlData: setUrlData,
    getDataForUrl : getDataForUrl,
    getLang: getLang,
    scrollToElement: scrollToElement,
    smallWin: smallWin,
    formatMoney: formatMoney,
    formatDate: formatDate,
    setCookie: setCookie,
    getCookie: getCookie,
    isObject: isObject,
    isMobile: isMobile,
    getSwipeDirection: getSwipeDirection
};