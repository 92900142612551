import functions from './functions';

$(() => {
    inquiry.init();
});

const inquiry = {
    init() {
        $('[id^=enquiryModal]').on('shown.bs.modal', e => {
            let params = functions.getUrlData(true);
            let dates = params.dates;
    
            if(dates || dates == '') {
                const aDates = dates.split(' - ');
                params.dateFrom = aDates[0] ? aDates[0] : null;
                params.dateTo = aDates[1] ? aDates[1] : null;
            }
    
            const $flatpickr = $(e.currentTarget).find($('.datepicker-range'))[0]._flatpickr;
    
            if(params.dateFrom) {
                const $departure = $('.departure[data-datefrom=' + params.dateFrom + ']');
                params.dateTo = $departure[0] ? $departure.attr('data-dateto') : params.dateTo;
                $flatpickr.setDate(params.dateTo ? [params.dateFrom, params.dateTo] : [params.dateFrom]);
            }
    
            const unitId = $(e.currentTarget).attr('data-unitid');
            params.guests = params.guests ? params.guests : $('.unit[data-unitid=' + unitId + ']').find('[name=guests]').val();
            
            if(params.guests) {
                const $guests = $(e.currentTarget).find('[name=guests]');
                $guests.val(params.guests).trigger('change');
            }
        });
    
        $('form#inquiry').on('submit', e => {
            e.preventDefault();
    
            const $form = $(e.currentTarget);
    
            let params = {};
            $.each($(e.currentTarget).serializeArray(), function (i, field) {
                params[field.name] = field.value;
            });
    
            const $dates = $form.find('[name="dates"]');
            const $guests = $form.find('[name="guests"]');
            $dates.parents('.datepicker-range').toggleClass('focus', ! params['dates'] && ! $dates.val());
            $guests.parents('.guests').toggleClass('focus', ! params['guests'] && ! $guests.val());
    
            $dates.on('change', e => {
                $(e.currentTarget).parents('.datepicker-range').removeClass('focus');
            });
            $guests.on('change', e => {
                $(e.currentTarget).parents('.guests').removeClass('focus');
            });
    
            if(params['dates'] && params['guests']) {
                $('.contact-btn').addClass('d-none');
                $('.contact-spinner').removeClass('d-none');
                $('.contact-spinner').addClass('d-flex');
        
                params['language'] = functions.getLang();
        
                params['requestId'] = Math.floor(Math.random() * ((9999999 - 1000000) + 1) + 1000000);
        
                grecaptcha.ready(function () {
                    grecaptcha.execute('6LfJGFopAAAAACdVyXFKTpwJcDucy9CIiphMNilY', { action: 'contact' }).then(function (token) {
                        params['gRecaptchaToken'] = token;
                        params = functions.getDataForUrl(params);
                        $.post('/services/inquiry/', params).then(response => {
        
                            //console.log(response);
                            //response = JSON.parse(response);
        
                            response.data = response.status 
                                ? 'Vaš upit je uspješno primljen. <br>Odgovoriti ćemo Vam u najkraćem mogućem roku.'
                                : 'Poštovani, došlo je do pogreške. <br>Pokušajte ponovno kasnije.';
                            
                            $('[id^=enquiryModal]').modal('hide');
                            $('#enquiry-modal').modal('show');
                            $(".response-output").html(response.data);
        
                            $('.contact-btn').removeClass('d-none');
                            $('.contact-spinner').removeClass('d-flex');
                            $('.contact-spinner').addClass('d-none');
        
                            setTimeout(() => { $('#enquiry-modal').modal('hide') }, 6000);
        
                            if (response.status == true) {
                                e.currentTarget.reset();
                            }
                        });
                    });
                });
            }
    
        });
    }

};

export {
    inquiry
}
